<template>
  <div class="naver-map-wrap w-full h-full">
    <div id="vue-naver-map" class="w-full h-full" />
  </div>
</template>

<script>
export default {
  name: "NaverMap",
  props: {
    center: Object,
    markers: Array,
    draggable: {
      type: Boolean,
      default: true,
    },
    scrollWheel: {
      type: Boolean,
      default: false,
    },
    onChangeCenter: {
      type: Function,
      default() {},
    },
    onChangeDistance: {
      type: Function,
      default() {},
    },
    onChangePoints: {
      type: Function,
      default() {},
    },
  },
  data() {
    return {
      map: null,
      listener: null,
      zoomChangeListener: null,
    };
  },
  mounted() {
    if (window.naver) {
      this.loadNaverMaps();
    } else {
      document.getElementById("vue-naver-map").onload = () =>
        (window.naver.maps.onJSContentLoaded = this.loadNaverMaps);
    }
  },
  beforeDestroy() {
    if (this.listener) {
      window.naver.maps.Event.removeListener(this.listener);
    }
    if (this.zoomChangeListener) {
      window.naver.maps.Event.removeListener(this.zoomChangeListener);
    }
  },
  destroyed() {
    window.$naverMapsLoaded = false;
  },
  methods: {
    getDistanceBetweenTwoPoints(cord1, cord2) {
      if (cord1.lat == cord2.lat && cord1.lon == cord2.lon) {
        return 0;
      }

      const radlat1 = (Math.PI * cord1.lat) / 180;
      const radlat2 = (Math.PI * cord2.lat) / 180;

      const theta = cord1.lon - cord2.lon;
      const radtheta = (Math.PI * theta) / 180;

      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

      if (dist > 1) {
        dist = 1;
      }

      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;

      return parseInt(dist * 1000);
    },
    loadNaverMaps() {
      this.map = new window.naver.maps.Map("vue-naver-map", {
        center: this.center,
        pinchZoom: false,
        scrollWheel: this.scrollWheel,
        draggable: this.draggable,
      });

      window.$naverMapsCallback?.forEach((v) => v(this.map));
      window.$naverMapsCallback = [];
      window.$naverMapsLoaded = true;
      window.$naverMapsObject = this.map;

      this.listener = window.naver.maps.Event.addListener(
        this.map,
        "dragend",
        () => {
          const c = this.map.getCenter();
          const bound = this.map.getBounds();

          this.onChangeCenter(c);

          const distance1 = this.getDistanceBetweenTwoPoints(
            { lat: bound.maxY(), lon: c.x },
            { lat: c.y, lon: c.x }
          );
          const distance2 = this.getDistanceBetweenTwoPoints(
            { lat: c.y, lon: bound.maxX() },
            { lat: c.y, lon: c.x }
          );

          this.changePointListenerEvent();
          return this.onChangeDistance(Math.min(distance1, distance2));
        }
      );

      this.zoomChangeListener = window.naver.maps.Event.addListener(
        this.map,
        'zoom_changed',
        () => this.changePointListenerEvent()
      );

      this.changePointListenerEvent();

      this.$emit("load", this);
    },
    loadMarkers() {
      if (this.map === null) {
        return;
      }

      this.markers.map((marker) => {
        const { lat, lng } = marker;
        new window.naver.maps.Marker(
          Object.assign(
            {
              position: new window.naver.maps.LatLng(lat, lng),
              map: this.map,
            },
            {}
          )
        );
      });

      this.$emit("load", this);
    },
    changePointListenerEvent() {
      const bound = this.map.getBounds();
      return this.onChangePoints(bound.minY(), bound.minX(), bound.maxY(), bound.maxX());
    },
  },
  watch: {
    center(newValue) {
      if (this.map) {
        const c = new window.naver.maps.LatLng(newValue.lat, newValue.lng);
        this.map.setCenter(c);
      }

      this.changePointListenerEvent();
    },
    markers() {
      this.loadMarkers();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
