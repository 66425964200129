<template>
  <div class="jumbotron-wrapper w-full h-full">
    <div class="w-full h-full content-wrapper flex flex-row">
      <div class="flex-1 h-full map-wrapper relative" ref="mapBox">
        <NaverMap
          :center="center"
          :markers="markers"
          :scrollWheel="true"
          :onChangePoints="onChangePoints"
        />

        <LocationFilter
          :filter="filter"
          :addr="addr"
          :onChange="onChangeFilter"
        />
      </div>

      <div class="list-wrapper flex flex-col">
        <div class="header flex justify-center items-center">
          <h3>현재 지역 총 {{ places.length }}개</h3>
        </div>

        <div class="flex-1 list-content">
          <div
            v-if="places.length === 0"
            class="h-full no-result flex justify-center items-center"
          >
            결과가 없습니다.
          </div>
          <div class="item-wrapper" v-for="item in places" :key="item.id">
            <PlaceSearchItem 
              :data="item"
              :isMap="true"
            />
          </div>
        </div>
      </div>
      <GeoLocation />
    </div>
  </div>
</template>

<script>
import PlaceSearchItem from "@/components/PlaceSearchItem.vue";
import GeoLocation from "@/components/GeoLocation.vue";
import LocationFilter from "@/components/LocationFilter.vue";
import NaverMap from "@/components/NaverMap.vue";
import { mapState } from "vuex";
import services from "@/services";
import axios from "axios";
import _ from "lodash";

export default {
  name: "PlaceList",
  components: {
    PlaceSearchItem,
    GeoLocation,
    LocationFilter,
    NaverMap,
  },
  data() {
    return {
      center: {
        lat: 37.566535,
        lng: 126.97796920000001,
      },
      places: [],
      filter: {},
      addr: "",
      minPrice: 0,
      maxPrice: 1000000000,
      classificationId: null,
      distance: 2000,
      mapPoints: {
        minLat: null,
        minLon: null,
        maxLat: null,
        maxLon: null,
      },
    };
  },
  computed: {
    markers: function() {
      return this.places
        .map((place) => ({
          lat: place.latitude,
          lng: place.longitude,
        }))
        .filter((place) => place.lat && place.lng);
    },
    ...mapState("place", ["location"]),
  },
  mounted() {
    const { query } = this.$route.query;
    if (query) {
      this.filter.addr = query;
      this.addr = query;
    }
  },
  created() {
    const { latitude, longitude } = this.location;
    if (latitude && longitude) {
      this.center = { lat: latitude, lng: longitude };
    }
  },
  methods: {
    onMapLoad() {},
    async getPlaces() {
      const newFilter = {
        // lat: this.center.lat,
        // lon: this.center.lng,
        minLat: this.mapPoints.minLat,
        minLon: this.mapPoints.minLon,
        maxLat: this.mapPoints.maxLat,
        maxLon: this.mapPoints.maxLon,
      };
      // newFilter.addr = this.addr;
      // newFilter.distance = this.distance;

      if (this.minPrice !== 0) {
        newFilter.minPrice = this.minPrice;
      }

      if (this.maxPrice !== 1000000000) {
        newFilter.maxPrice = this.maxPrice;
      }

      if (this.classificationId) {
        newFilter.classificationId = this.classificationId;
      }

      // newFilter.addr2 = newFilter.addr;
      newFilter.is_add_distance = '1';
      newFilter.is_order_distance = '1';

      const data = await services.placeList(newFilter);
      this.places = data;
    },
    onChangeFilter({ type, value }) {
      switch (type) {
        case "addr":
          this.addr = value;
          this.filter.addr = value;
          break;
        case "minPrice":
          this.minPrice = value;
          this.filter.minPrice = value;
          break;
        case "maxPrice":
          this.maxPrice = value;
          this.filter.maxPrice = value;
          break;
        case "classificationId":
          this.classificationId = value;
          this.filter.classificationId = value;
          break;
        default:
          break;
      }
    },
    async getKakaoPlace() {
      const kakaoKey = "a6ccc2bf513d01266f264d20a3cb24bb";
      if (!this.addr.trim() || !kakaoKey || this.addr.length < 2) {
        alert('주소를 최소 2글자 이상 입력해주세요.');
        return;
      }

      const res = await axios.get(
        "https://dapi.kakao.com/v2/local/search/keyword.json",
        {
          headers: { Authorization: `KakaoAK ${kakaoKey}` },
          params: {
            query: this.addr,
            size: 15,
          },
        }
      );
      const { documents } = res.data;
      if (documents.length > 0) {
        const { x, y } = documents[0];
        this.center = {
          lat: y,
          lng: x,
        };
      } else {
        alert('동일한 키워드를 찾을 수 없습니다.\n검색어는 키워드 형식으로 입력해주세요.');
      }
    },
    onChangeCenter(c) {
      this.center = { lat: c.y, lng: c.x };
    },
    onChangeDistance(d) {
      this.distance = d;
    },
    onChangePoints(minLat, minLon, maxLat, maxLon) {
      // console.log(`onChangePoints => \nminLat: ${minLat}\nminLon: ${minLon}\nmaxLat: ${maxLat}\nmaxLon: ${maxLon}`);

      this.mapPoints = {
        minLat: minLat,
        minLon: minLon,
        maxLat: maxLat,
        maxLon: maxLon,
      };

      this.getPlaces();
    }
  },
  watch: {
    location(newValue) {
      const { latitude, longitude } = newValue;
      if (latitude && longitude) {
        this.center = { lat: latitude, lng: longitude };
      }
    },
    center() {
      // this.getPlaces();
    },
    distance() {
      // this.getPlaces();
    },
    minPrice: _.debounce(function(newValue) {
      console.log("C", newValue);
      this.getPlaces();
    }, 100),
    maxPrice() {
      this.getPlaces();
    },
    addr() {
      this.getKakaoPlace();
    },
    classificationId() {
      this.getPlaces();
    },
  },
};
</script>

<style lang="scss" scoped>
.jumbotron-wrapper {
  margin-top: 100px;
}

.content-wrapper {
  border-top: 1px solid #c9c9c9;
  height: 1112px;
}

.list-wrapper {
  width: 496px;
  height: 100%;
  border-left: 1px solid #c9c9c9;

  .header {
    height: 73px;
    border-bottom: 1px solid #c9c9c9;

    h3 {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.4;
      letter-spacing: -1px;
      text-align: center;
      color: #616161;
    }
  }

  .list-content {
    overflow-y: scroll;
    padding-bottom: 30px;
  }

  .map-wrapper {
    background-color: #c9c9c9;
  }

  .no-result {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.4;
    letter-spacing: -1px;
    text-align: center;
    color: #616161;
  }
}

.item-wrapper {
  cursor: pointer;
  padding-left: 17px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (max-width: 1279px) {
  .jumbotron-wrapper {
    .content-wrapper {
      .map-wrapper {
        width: 100%;
        flex: unset;
        height: unset;
        flex-direction: column;
      }
    }
  }
  .item-wrapper {
    padding: 5px 10px;
  }
}

@media (max-width: 1023px) {
  .jumbotron-wrapper {
    margin-top: 56px;
  }
  .list-wrapper {
    width: 250px;
    .header {
      height: unset;
      padding: 10px;
      h3 {
        font-size: 13px;
        line-height: 1.2;
      }
    }
  }
  .content-wrapper {
    height: calc(100vh - 56px);
  }
}

@media (max-width: 639px) {
  .list-wrapper {
    width: 150px;
    .no-result {
      font-size: 12px;
    }
    .header {
      padding: 5px;
      h3 {
        font-size: 11px;
      }
    }
    .item-wrapper {
      margin: 5px 0;
    }
  }
}
</style>
