<template>
  <div class="absolute filter-wrapper">
    <div class="search flex flex-row items-center">
      <input
        v-model="innerAddr"
        @keyup.enter="onSubmitAddr"
        class="flex-1"
        placeholder="주소, 지하철역, 학교 검색"
      />
      <button @click="onSubmitAddr" class="flex items-center justify-center">
        <img src="@/assets/search.png" />
      </button>
    </div>

    <div class="info flex flex-row items-center">
      <button
        class="flex flex-row items-center"
        @click="onCollapse"
        :class="{ active: !isCollapse }"
      >
        <img v-if="!isCollapse" src="@/assets/filter.png" />
        검색조건
      </button>
    </div>

    <hr v-if="!isCollapse" />

    <div v-if="!isCollapse" class="slide-group">
      <p class="label">금액</p>

      <vue-slider
        v-model="price"
        direction="ltr"
        :marks="marks"
        :width="'100%'"
        :min="0"
        :max="1000000000"
        :interval="this.windowWidth < 640 ? 200000000 : 50000000"
        :process="true"
        :process-style="{ backgroundColor: '#283aef' }"
        tooltip="none"
      >
        <template v-slot:dot="{ focus }">
          <div :class="['filter-dot', { focus }]"></div>
        </template>
      </vue-slider>
    </div>

    <div v-if="!isCollapse" class="button-group">
      <p class="label">형태</p>
      <div class="flex flex-row items-center justify-start flex-wrap">
        <button
          @click="
            () =>
              onClassification(item.id, !classificationIds.includes(item.id))
          "
          :class="{ active: classificationIds.includes(item.id) }"
          v-for="item in classifications"
          :key="item.id"
        >
          {{ item.title }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "LocationFilter",
  components: {
    VueSlider,
  },
  props: {
    addr: String,
    filter: Object,
    onChange: Function,
  },
  data() {
    return {
      innerAddr: "",
      isCollapse: true,
      classificationIds: [],
      price: [0, 1000000000],
      marks: (val) =>
        val % 100000000
          ? false
          : {
              label: `${val === 0 ? "최소" : ""}${
                val === 1000000000 ? "최대" : ""
              }${
                val !== 0 && val !== 1000000000 ? `${val / 100000000}억원` : ""
              }`,
              labelStyle: {
                fontSize: "10px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                letterSpacing: "-0.5px",
                textAlign: "center",
                color: "#afafaf",
              },
            },
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState("place", ["leases", "classifications"]),
  },
  methods: {
    onCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    onClassification(id, active) {
      if (active) {
        this.classificationIds.push(id);
      } else {
        this.classificationIds = this.classificationIds.filter((c) => c !== id);
      }
    },
    onSubmitAddr() {
      this.onChange({ type: "addr", value: this.innerAddr });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  watch: {
    price(newValue) {
      this.onChange({ type: "minPrice", value: newValue[0] });
      this.onChange({ type: "maxPrice", value: newValue[1] });
    },
    classificationIds: _.debounce(function(newValue) {
      this.onChange({ type: "classificationId", value: newValue.join(",") });
    }, 300),
    addr(newValue) {
      if (newValue !== this.innerAddr) {
        this.innerAddr = newValue;
      }
    },
    windowWidth(newWidth) {
      this.windowWidth = newWidth;
    },
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
};
</script>

<style scoped lang="scss">
.filter-wrapper {
  width: 425px;
  background-color: #fff;
  top: 27px;
  right: 43px;
  padding: 25px 25px 42px 25px;
  border: solid 1px #c9c9c9;
  transition: height 0.5s;

  .search {
    height: 38px;
    margin-bottom: 14px;

    input {
      height: 38px;
      border: 1px solid #bdbdbd;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.7px;
      padding-left: 19px;
      padding-right: 19px;

      ::placeholder {
        color: #bdbdbd;
      }
    }

    button {
      width: 41px;
      height: 38px;
      background-color: #bdbdbd;
      border: 0;
    }
  }

  .info {
    button {
      height: 32px;

      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.7px;
      text-align: center;
      color: #bababa;
      border: 1px solid #e5e5e5;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 6px;

      img {
        margin-right: 6px;
      }

      &.active {
        color: #283aef;
        border: 1px solid #283aef;
        font-weight: normal;
      }
    }
  }

  hr {
    margin-top: 28px;
    margin-bottom: 26px;
  }

  .button-group {
    .label {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.7px;
      color: #7c7c7c;
      margin-bottom: 10px;
    }

    button {
      width: 112px;
      height: 34px;
      background-color: #fff;
      border: 1px solid #c1c1c1;

      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.7px;
      text-align: center;
      color: #909090;

      margin-bottom: 7px;
      margin-right: 11px;

      &.active {
        background-color: #283aef;
        color: #fff;
      }
    }
  }

  .slide-group {
    margin-top: 35px;
    margin-bottom: 40px;

    .label {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.7px;
      color: #7c7c7c;
      margin-bottom: 10px;
    }

    .filter-dot {
      width: 14px;
      height: 14px;
      border-radius: 0;
      background-color: #283aef;
      transition: all 0.3s;
    }
  }

  .recent {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.43;
    letter-spacing: -0.7px;
    text-align: center;
    color: #bababa;
    margin-right: 9px;
    margin-left: 5px;
  }
}

@media (max-width: 1279px) {
  .filter-wrapper {
    width: 100%;
    top: 0;
    left: 0;
    right: unset;
  }
}

@media (max-width: 1023px) {
  .filter-wrapper {
    padding: 10px 15px;
    .search {
      height: 25px;
      margin-bottom: 10px;
      input {
        height: 100%;
        font-size: 11px;
        padding: 0 10px;
      }
      button {
        width: 30px;
        height: 100%;
        img {
          width: 15px;
          object-fit: contain;
        }
      }
    }
    .info {
      button {
        height: 25px;
        font-size: 11px;
      }
    }
    hr {
      margin: 10px 0;
    }
    .slide-group {
      margin: 20px 0 30px;
      .label {
        font-size: 11px;
      }
      .filter-dot {
        width: 10px;
        height: 10px;
        margin-top: 2px;
      }
    }
    .button-group {
      .label {
        font-size: 11px;
      }
      button {
        width: 50px;
        height: 25px;
        font-size: 11px;
      }
    }
  }
}
</style>
